import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { verifyJson } from "utils/validateAemJson";
import { globalPaths } from "@RHCommerceDev/aem/globalModels";
import { globalModels } from "@RHCommerceDev/aem/globalModels";
import { getAemEnv } from "@RHCommerceDev/aem/getAemEnv";
import { useIsoCookies } from "hooks/useIsoCookies";
import { useEnv } from "hooks/useEnv";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import yn from "yn";
import {
  AemFetchHookGlobalsResult,
  AemFetchHookGlobalsVariables,
  GlobalPaths
} from "aem/fetchModel";

const PRIMARY = "primary";
const SECONDARY = "secondary";
export const processModelResponse = (data, path = "getAemModel") => {
  const escapeXml = unsafe => {
    return unsafe.replace(/(&lt;|&gt;|&amp;|&apos;|&quot;)/g, function (c) {
      switch (c) {
        case "&lt;":
          return "<";
        case "&gt;":
          return ">";
        case "&amp;":
          return "&";
        case "&apos;":
          return "'";
        case "&quot;":
          return '"';
      }
    });
  };
  let pageContent = verifyJson(data, path) ? JSON.parse(data[path]?.model) : {};

  Object.keys(data ?? {}).forEach(
    key => {
      if (verifyJson(data, key) && pageContent) {
        pageContent[key] = JSON.parse(data[key]?.model || "");
      }
    },
    { pageContent, data }
  );
  const recursion = obj => {
    for (let i in obj) {
      if (typeof obj[i] == "object") {
        recursion(obj[i]);
      } else {
        typeof obj[i] === "string" && escapeXml(obj[i]);
      }
    }
  };
  recursion(pageContent);
  return pageContent;
};

export const globalQueryVariables = modelPath => {
  const aemEnv = getAemEnv();
  return {
    aemModelInput: {
      modelPath
    },
    ...Object.fromEntries(
      Object.entries(globalModels).map(([key, value]) => {
        return [
          key,
          {
            modelPath: globalPaths[key],
            aemEnv
          }
        ];
      })
    )
  };
};

export const queryGetAemModelGlobal = gql`
  query GetAemModel(
    $aemModelInput: AemModelInput!
    ${Object.keys(globalModels || {}).map(key => {
      return `$${key}: AemModelInput!`;
    })}
  ) {
    ${Object.keys(globalModels || {}).map(key => {
      return `${key}: getAemModel(aemModelInput: $${key}) {
        model
      }`;
    })}

    getAemModel(aemModelInput: $aemModelInput) {
      model
    }
  }
`;

const useIsFeatureHeroHp = () => {
  const cookies = useIsoCookies();
  const featureHpHeroCookie = cookies["FEATURE_HP_HERO"];
  const env = useEnv();
  const featureHpHero = featureHpHeroCookie
    ? yn(featureHpHeroCookie)
    : yn(env.FEATURE_HP_HERO);
  return featureHpHero;
};

const useGetExperimentCookie = () => {
  const cookies = useIsoCookies();

  const hp_hero = cookies["hp_hero"];

  let valHpHero;
  if (processEnvServer) {
    /* 
    why here is the inverse 
      because we get the request ie with hp_hero=primary
      on the server we need to use the opposite 
      wehn we return this request the set-cookie header says hp_hero=secondary
      this way the graphQL calls are the same on the server and front end and we can use the cache one
    */
    valHpHero = hp_hero === PRIMARY ? SECONDARY : PRIMARY;
  } else {
    valHpHero = hp_hero ? hp_hero : PRIMARY;
  }

  return valHpHero;
};

const useBuildExtraArguments = (isAEMHomePage?: boolean) => {
  const featureHeroHp = !!useIsFeatureHeroHp();
  const heroCookie = useGetExperimentCookie();

  if (!isAEMHomePage) {
    return {
      featureHeroHp: false,
      hp_hero: ""
    };
  }

  return {
    featureHeroHp,
    hp_hero: heroCookie ?? ""
  };
};

const buildExtraPathVariables = (globalPaths: GlobalPaths = {}) => {
  return Object.fromEntries(
    Object.entries(globalPaths).map(([key, value]: [string, string]) => {
      return [key, { modelPath: value?.replace(/\/\/+/g, "/") }];
    })
  );
};

export const globalOnlyAemModel = (
  variables: AemFetchHookGlobalsVariables
): AemFetchHookGlobalsResult => {
  const firstPart = Object.keys(variables?.globalPaths || {}).map(key => {
    return `$${key}: AemModelInput!`;
  });

  const secondPart = Object.keys(variables?.globalPaths || {}).map(key => {
    return `${key}: getAemModel(aemModelInput: $${key}) {
      model
    }`;
  });

  const constructedQuery = `
  query GetAemModel(
    ${firstPart}
  ) {
    ${secondPart}
  }
`;
  const extraVariables = buildExtraPathVariables(variables?.globalPaths);

  const queryGetAemModelGlobal = gql`
    ${constructedQuery}
  `;

  return useQuery(queryGetAemModelGlobal, {
    variables: { ...extraVariables },
    client: variables.graphqlClient,
    skip: variables?.skip || false
  });
};

export const useGlobalAemModel = variables => {
  const firstPart = Object.keys(variables?.globalPaths || {}).map(key => {
    return `$${key}: AemModelInput!`;
  });

  const secondPart = Object.keys(variables?.globalPaths || {}).map(key => {
    return `${key}: getAemModel(aemModelInput: $${key}) {
      model
    }`;
  });

  const constructedQuery = `
  query GetAemModel(
    $aemModelInput: AemModelInput!
    ${firstPart}
  ) {
    ${secondPart}

    getAemModel(aemModelInput: $aemModelInput) {
      model
    }
  }
`;

  const queryGetAemModelGlobal = gql`
    ${constructedQuery}
  `;

  const sanitizePath = variables.modelPath.replace(/\/\/+/g, "/");

  const extraArguments = useBuildExtraArguments(variables?.isAEMHomePage);
  const extraVariables = buildExtraPathVariables(variables?.globalPaths);

  return useQuery(queryGetAemModelGlobal, {
    variables: {
      aemModelInput: {
        modelPath: sanitizePath,
        ...extraArguments
      },
      ...extraVariables
    },
    client: variables.graphqlClient,
    skip: variables?.skip || false
  });
};

export const aemModel = variables => {
  const aemEnv = getAemEnv();
  const sanitizePath = variables.modelPath.replace(/\/\/+/g, "/");

  return useQuery(queryGetAemModelGlobal, {
    variables: {
      aemModelInput: {
        modelPath: sanitizePath,
        aemEnv,
        ...useBuildExtraArguments()
      }
    },
    client: variables.graphqlClient
  });
};

export const queryGetAemModel = gql`
  query GetAemModel($aemModelInput: AemModelInput!) {
    getAemModel(aemModelInput: $aemModelInput) {
      model
    }
  }
`;
