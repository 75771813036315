import gql from "graphql-tag";

export const queryCmrDebugInfo = gql`
  {
    cmrDebugInfo {
      bffPath
      cartBrokerEndpoint
      colorizationEndpoint
      contentfulEnvironment
      fusionEndpoint
      isCmrEnabled
      isColorizationEnabled
      isPreviewEnabled
      isRedisCacheEnabled
    }
  }
`;
